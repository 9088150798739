html, body {
  overflow-x:hidden;
  position: relative;
}

body {
  margin: 0;
  font-family: Satoshi-Normal, -apple-system, 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;


  /* defaults */
  background-color: #000;
  color: #86868B;
  font-size: 1.25rem;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a {
  text-decoration: none;
}

/*Scrollbar*/
/* Track */
::-webkit-scrollbar-track {
  background: #000;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #001523;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #001523;
}
